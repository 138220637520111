"use client";
import { Spinner } from "@nextui-org/spinner";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

function IndexPage() {
  const router = useRouter();

  useEffect(() => {
    if (!router) return;
    router.replace("/empty-dashboard");
  }, [router]);

  return (
    <div className="absolute inset-0 bg-tlg-black w-full h-full z-20 flex items-center justify-center">
      <Spinner size="lg" />
    </div>
  );
}

export default IndexPage;
